import Image from 'gatsby-image'
import type { FC } from 'react'
import styled from 'styled-components'

import { isOpenSource } from '../constants/config'
import { useAvatar } from '../hooks/useAvatar'
import { useSiteDetails } from '../hooks/useSiteDetails'

import { Modal } from './Modal'
import { Divider } from './Overrides'
import { ShortcutCheatSheet } from './ShortcutCheatSheet'

export const Footer: FC = () => {
  const { author, sourceUrl } = useSiteDetails()
  const avatar = useAvatar()

  return (
    <FooterContainer>
      <StyledDivider />
      <div>
        © {new Date().getFullYear()}, built with
        {` `}
        <a href="https://www.gatsbyjs.org" rel="noopener noreferrer">
          Gatsby
        </a>
        . Subscribe via <a href="/rss.xml">RSS</a>.
        <br />
        {isOpenSource ? (
          <a href={sourceUrl} rel="noopener noreferrer">
            Source code
          </a>
        ) : (
          'Crafted'
        )}{' '}
        by {author}
        <Image
          alt={author}
          fixed={avatar}
          imgStyle={{
            borderRadius: `50%`,
          }}
          style={{
            margin: '0 8px',
          }}
        />
        . All rights reserved.
      </div>
      <Modal>
        <ShortcutCheatSheet />
      </Modal>
    </FooterContainer>
  )
}

const FooterContainer = styled.footer`
  grid-area: footer;
  height: ${({ theme }) => theme.fixedSizes.footer}px;
  width: 100%;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    position: relative;
    margin-bottom: calc(1.45rem - 1px);
    height: auto;
    padding-left: 0;
  }
`

const StyledDivider = styled(Divider)`
  height: 5px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`
