import type { FC } from 'react'
import type { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-core'

const BaseResults: FC<StateResultsProvided> = ({
  searchState: state,
  searchResults: res,
  children,
}) => {
  return (
    <>{res && res.nbHits > 0 ? children : `No results for '${state.query}'`}</>
  )
}

export const Results = connectStateResults(BaseResults)
