import type { FC } from 'react'
import { Helmet } from 'react-helmet'

import type { Content } from '../helpers/seo'
import { computeSchemaOrgJsonLd, preconnectLinks } from '../helpers/seo'
import { useAbout } from '../hooks/useAbout'
import { useSiteDetails } from '../hooks/useSiteDetails'

interface SEOProps {
  additionalMeta?: []
  description?: string
  imagePathname?: string
  keywords?: string[]
  lang?: string
  modifiedTime?: string
  pathname?: string
  publishedTime?: string
  title: string
  type?: Content
}

export const SEO: FC<SEOProps> = ({
  additionalMeta = [],
  description = '',
  imagePathname,
  keywords = [],
  modifiedTime = '',
  pathname = '/',
  publishedTime = '',
  lang = 'en',
  title,
  type = 'blog',
}) => {
  const { photo } = useAbout()
  const {
    author,
    description: rootDescription,
    handles,
    siteUrl,
    title: rootTitle,
  } = useSiteDetails()

  const metaDescription = description || rootDescription
  const metaImage = `${siteUrl}${photo.src || imagePathname}`
  const metaUrl = `${siteUrl}${pathname}`

  const schemaOrgJsonLd = computeSchemaOrgJsonLd({
    author,
    description,
    metaImage,
    metaUrl,
    modifiedTime,
    photoSrc: photo.src,
    publishedTime,
    rootTitle,
    siteUrl,
    title,
    type,
  })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${rootTitle}`}
      meta={additionalMeta}
    >
      {/* Helpful tags */}
      <base target="_blank" href="." />
      <link href={siteUrl} rel="author" />
      <meta content="index, follow" name="robots" />
      <link href={metaUrl} rel="canonical" />
      {preconnectLinks.map((link, ii) => (
        <link key={ii} href={link} rel="preconnect" />
      ))}

      {/* General tags */}
      <meta content={author} name="author" />
      <meta content={metaDescription} name="description" />
      <meta content={metaImage} name="image" />
      <meta content={title} name="title" />
      {keywords.length > 0 && (
        <meta content={keywords.join(', ')} name="keywords" />
      )}

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJsonLd)}
      </script>

      {/* OpenGraph tags */}
      <meta content={metaDescription} property="og:description" />
      <meta content={metaImage} property="og:image" />
      <meta content={rootTitle} property="og:site_name" />
      <meta content={title} property="og:title" />
      <meta content={type} property="og:type" />
      <meta content={metaUrl} property="og:url" />

      {/* Twitter Card tags */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={handles.twitter} name="twitter:creator" />
      <meta content={metaDescription} name="twitter:description" />
      <meta content={metaImage} name="twitter:image" />
      <meta content={metaImage} name="twitter:img:src" />
      <meta content={handles.twitter} name="twitter:site" />
      <meta content={title} name="twitter:title" />

      {/* Content Metadata Reference tags */}
      <meta content={metaUrl} property="al:web:url" />

      {/* Article tags */}
      {type === 'article' && (
        <meta content={author} property="article:author" />
      )}
      {type === 'article' && (
        <meta content={publishedTime} property="article:published_time" />
      )}
      {type === 'article' && (
        <meta content={siteUrl} property="article:publisher" />
      )}
    </Helmet>
  )
}
