import type { HitComponent } from './HitComponents'

export interface SearchIndex {
  hitComponent: HitComponent
  name: string
  title: string
}

export const searchIndices: SearchIndex[] = [
  { hitComponent: 'PostHit', name: 'Posts', title: 'Blog Posts' },
]
