import { graphql, useStaticQuery } from 'gatsby'

interface GQLSiteDetails {
  site: {
    siteMetadata: {
      author: string
      description: string
      email: string
      handles: {
        twitter: string
      }
      siteUrl: string
      sourceUrl: string
      title: string
    }
  }
}

export const useSiteDetails = (): {
  author: string
  description: string
  email: string
  handles: {
    twitter: string
  }
  siteUrl: string
  sourceUrl: string
  title: string
} => {
  const { site } = useStaticQuery<GQLSiteDetails>(graphql`
    {
      site {
        siteMetadata {
          author
          description
          email
          handles {
            twitter
          }
          title
          siteUrl
          sourceUrl
        }
      }
    }
  `)

  return {
    author: site.siteMetadata.author,
    description: site.siteMetadata.description,
    email: site.siteMetadata.email,
    handles: site.siteMetadata.handles,
    siteUrl: site.siteMetadata.siteUrl,
    sourceUrl: site.siteMetadata.sourceUrl,
    title: site.siteMetadata.title,
  }
}
