import { graphql, useStaticQuery } from 'gatsby'
import type { FluidObject } from 'gatsby-image'

interface GQLAbout {
  markdownRemark: {
    html: string
    fields: {
      path: string
    }
  }
  photo: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  site: {
    siteMetadata: {
      sourceUrl: string
    }
  }
}

export const useAbout = (): {
  filePath: string
  html: string
  photo: FluidObject
  sourceUrl: string
} => {
  const { markdownRemark, photo, site } = useStaticQuery<GQLAbout>(graphql`
    {
      markdownRemark(
        frontmatter: { contentType: { eq: "about" } }
        fileAbsolutePath: { regex: "/pages/" }
      ) {
        html
        fields {
          path
        }
      }
      photo: file(absolutePath: { regex: "/me-aviator.jpg/" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          sourceUrl
        }
      }
    }
  `)

  return {
    filePath: markdownRemark.fields.path,
    html: markdownRemark.html,
    photo: photo.childImageSharp.fluid,
    sourceUrl: site.siteMetadata.sourceUrl,
  }
}
