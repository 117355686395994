import type {
  BlogPosting,
  ImageObject,
  Organization,
  Person,
  WebSite,
} from 'schema-dts'

export type Content = 'article' | 'blog'

export const preconnectLinks = [
  'https://www.google.com',
  'https://marketingplatform.google.com',
]

export const computeSchemaOrgJsonLd = ({
  author,
  description,
  metaImage,
  metaUrl,
  modifiedTime,
  photoSrc,
  publishedTime,
  rootTitle,
  siteUrl,
  title,
  type,
}: {
  author: string
  description: string
  metaImage: string
  metaUrl: string
  modifiedTime: string
  photoSrc: string
  publishedTime: string
  rootTitle: string
  siteUrl: string
  title: string
  type: Content
}): Array<BlogPosting | WebSite> => {
  const schemaOrgJsonLd: Array<BlogPosting | WebSite> = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      alternateName: rootTitle,
      name: title,
      url: metaUrl,
    } as WebSite,
  ]

  if (type === 'article') {
    const person = {
      '@type': 'Person',
      name: author,
    } as Person

    const logo = {
      '@type': 'ImageObject',
      url: `${siteUrl}${photoSrc}`,
    } as ImageObject

    const organization = {
      '@type': 'Organization',
      logo,
      name: author,
    } as Organization

    schemaOrgJsonLd.push({
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      alternateName: rootTitle,
      author: person,
      dateModified: modifiedTime,
      datePublished: publishedTime,
      description,
      headline: title,
      image: {
        '@type': 'ImageObject',
        url: metaImage,
      },
      mainEntityOfPage: metaUrl,
      name: title,
      publisher: organization,
      url: metaUrl,
    } as BlogPosting)
  }

  return schemaOrgJsonLd
}
