import type { FC } from 'react'
import styled from 'styled-components'

import { Footer } from './Footer'
import { Header } from './Header'

export const Layout: FC = ({ children }) => (
  <LayoutContainer>
    <Header />
    <MainContainer>{children}</MainContainer>
    <Footer />
  </LayoutContainer>
)

const LayoutContainer = styled.section`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.fixedSizes.header}px 1fr;
  grid-template-areas:
    'header header'
    'sidebar content'
    'footer footer';
  grid-column-gap: 10px;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;

  @media (max-width: ${({ theme }) => theme.breaks.XL}) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    flex-direction: column;
    padding: 0 20px;
  }
`

const MainContainer = styled.main`
  grid-area: content;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    grid-area: sidebar / sidebar / sidebar / content;
  }
`
