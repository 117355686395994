import type { FC } from 'react'
import { useContext } from 'react'
import styled from 'styled-components'

import {
  colorDarkPrimaryLightSecondary,
  colorDarkWhiteLightGray,
  ModeContext,
  transitionizeProperties,
} from '../constants/theme'
import { useKeyPress } from '../hooks/useKeyPress'

export const ThemeSwitch: FC = () => {
  const { isDark, toggleDark } = useContext(ModeContext)
  useKeyPress('d', () => {
    toggleDark()
  })

  return (
    <div>
      <Switch className="theme-switch" htmlFor="checkbox" title="Toggle theme">
        <Checkbox
          checked={isDark}
          id="checkbox"
          onChange={toggleDark}
          type="checkbox"
        />
        <Slider />
      </Switch>
    </div>
  )
}

const Switch = styled.label`
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
`

const Slider = styled.div`
  background-color: ${colorDarkWhiteLightGray};
  ${transitionizeProperties(['background-color'])};
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 34px;

  // NOTE: this is the thumb circle
  :before {
    background-color: ${colorDarkPrimaryLightSecondary};
    bottom: 4px;
    content: '';
    height: 26px;
    left: 4px;
    position: absolute;
    transition: 0.2s;
    width: 26px;
    border-radius: 50%;
  }
`

const Checkbox = styled.input`
  opacity: 0;
  width: 100%;
  height: 100%;

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`
