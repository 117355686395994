import type { FC } from 'react'
import type { StateResultsProvided } from 'react-instantsearch-core'
import { connectStateResults } from 'react-instantsearch-core'

const BaseStats: FC<StateResultsProvided> = ({ searchResults: res }) => {
  return (
    <>
      {res &&
        res.nbHits > 0 &&
        `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`}
    </>
  )
}

export const Stats = connectStateResults(BaseStats)
