import { graphql, useStaticQuery } from 'gatsby'
import type { FixedObject } from 'gatsby-image'

interface GQLAvatar {
  avatar: {
    childImageSharp: {
      fixed: FixedObject
    }
  }
}

export const useAvatar = (): FixedObject => {
  const { avatar } = useStaticQuery<GQLAvatar>(graphql`
    {
      avatar: file(absolutePath: { regex: "/avatar.jpg/" }) {
        childImageSharp {
          fixed(width: 25, height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return avatar.childImageSharp.fixed
}
