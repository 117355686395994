import type { CSSProperties, FC } from 'react'
import styled from 'styled-components'

import {
  colorDarkSecondaryLightPrimary,
  colorDarkWhiteLightGray,
  transitionizeProperties,
} from '../constants/theme'

export const HTMLContent = styled.section`
  th,
  td {
    ${transitionizeProperties(['border-color'])};
    border-right: 1px solid ${colorDarkWhiteLightGray};
    border-bottom: 1px solid ${colorDarkWhiteLightGray};
  }

  th:last-child,
  td:last-child {
    border-right: none;
  }

  tr:last-child td {
    border-bottom: none;
  }
`

const StyledHr = styled.hr`
  ${transitionizeProperties(['background-color'])};
  background-color: ${colorDarkSecondaryLightPrimary};
  height: 1px;
  width: 100%;
`

interface DividerProps {
  className?: string
  style?: CSSProperties
}

export const Divider: FC<DividerProps> = ({ className, style }) => (
  <StyledHr className={className} style={style} />
)
