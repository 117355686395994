import type { FC } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { QuestionCircle } from 'styled-icons/fa-regular'
import { Cross } from 'styled-icons/icomoon'

import {
  colorDarkGrayLightWhite,
  colorDarkWhiteLightGray,
  transitionizeProperties,
} from '../constants/theme'
import { useKeyPress } from '../hooks/useKeyPress'

import { Portal } from './Portal'

export const Modal: FC = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  useKeyPress('escape', () => {
    setIsModalVisible(false)
  })

  useKeyPress('?', () => {
    setIsModalVisible(true)
  })

  const handleOpenButtonClick = () => {
    setIsModalVisible(true)
  }

  const handleCloseButtonClick = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <OpenButton
        aria-label="Open shortcut cheat sheet"
        onClick={handleOpenButtonClick}
        title="Open shortcut cheat sheet"
      >
        <StyledQuestionCircle size="1em" title="Shortcuts" />
      </OpenButton>

      {isModalVisible && (
        <Portal>
          <Backdrop />
          <ModalContainer
            onClick={(event) => {
              if (event.target !== event.currentTarget) {
                return
              }

              setIsModalVisible(false)
            }}
          >
            <ModalBox>
              <CloseButton
                aria-label="Close shortcut cheat sheet"
                onClick={handleCloseButtonClick}
                title="Close shortcut cheat sheet"
              >
                <StyledCross size="1em" />
              </CloseButton>
              {children}
            </ModalBox>
          </ModalContainer>
        </Portal>
      )}
    </>
  )
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.layers.modal};
  background-color: rgba(0, 0, 0, 0.85);
`

const ModalBox = styled.div`
  background-color: ${colorDarkGrayLightWhite};
  border: 1px solid ${colorDarkWhiteLightGray};
  ${transitionizeProperties(['border-color', 'background-color'])};
  width: calc(100% - 60px);
  max-width: 900px;
  padding: 80px 40px;
  border-radius: 5px;
  position: relative;
`

const BaseButton = styled.button`
  padding: 0;
  margin: 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: transparent;
  border: none;
`

const OpenButton = styled(BaseButton)`
  position: absolute;
  right: 20px;
  top: 20px;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    position: relative;
    right: 0;
    top: 0;
    margin-top: 20px;
  }
`

const StyledQuestionCircle = styled(QuestionCircle)`
  color: ${colorDarkWhiteLightGray};
  ${transitionizeProperties(['color'])};
  width: 25px;
  display: block;
  height: 25px;
`

const CloseButton = styled(BaseButton)`
  position: absolute;
  top: 20px;
  right: 20px;
`

const StyledCross = styled(Cross)`
  color: ${colorDarkWhiteLightGray};
  ${transitionizeProperties(['color'])};
  width: 25px;
  display: block;
  height: 25px;
`
