import { navigate } from 'gatsby'
import type { FC, InputHTMLAttributes } from 'react'
import type { Hit, SearchBoxProvided } from 'react-instantsearch-core'
import { connectHits, connectSearchBox } from 'react-instantsearch-dom'

import type { HitData } from './HitComponents'
import { Form, Input, SearchIcon } from './Styles'

interface ConnectedHit extends HitData {
  _highlightResult: never
}

interface SearchBoxProps
  extends SearchBoxProvided,
    InputHTMLAttributes<HTMLInputElement> {
  hits: Array<Hit<ConnectedHit>>
  shouldCollapse: boolean
  shouldFocus: boolean
}

const BaseSearchBox: FC<SearchBoxProps> = ({ hits, refine, ...rest }) => (
  <Form
    onSubmit={(event) => {
      event.preventDefault()

      if (hits.length > 0) {
        navigate(hits[0].slug)
      } else {
        return
      }
    }}
  >
    <Input
      type="text"
      placeholder="Search"
      aria-label="Search"
      onChange={(e) => refine(e.target.value)}
      {...rest}
    />
    <SearchIcon />
  </Form>
)

export const SearchBox = connectHits(connectSearchBox(BaseSearchBox))
