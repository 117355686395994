import type { FC } from 'react'
import { createPortal } from 'react-dom'

import { usePortalElement } from '../hooks/usePortalElement'

export const Portal: FC = ({ children }) => {
  const modalTarget = usePortalElement('modal-root')

  return createPortal(children, modalTarget)
}
