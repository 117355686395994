import { Link } from 'gatsby'
import type { FC } from 'react'
import { Fragment } from 'react'
import type { Hit } from 'react-instantsearch-core'
import { Highlight, Snippet } from 'react-instantsearch-dom'
import { Tags } from 'styled-icons/fa-solid'
import { Calendar } from 'styled-icons/octicons'

export interface HitData {
  date: string
  excerpt: string
  objectID: string
  slug: string
  tags: string[]
}

interface PostHitProps {
  hit: Hit<HitData>
  onClick: () => void
}

export const PostHit: FC<PostHitProps> = ({ hit, onClick }) => (
  <div>
    <Link to={hit.slug} onClick={onClick}>
      <h4>
        <Highlight attribute="title" hit={hit} tagName="mark" />
      </h4>
    </Link>
    <div>
      <Calendar size="1em" />
      &nbsp;
      <Highlight attribute="date" hit={hit} tagName="mark" />
      &emsp;
      <Tags size="1em" />
      &nbsp;
      {hit.tags.map((tag, index) => (
        <Fragment key={tag}>
          {index > 0 && `, `}
          {tag}
        </Fragment>
      ))}
    </div>
    <Snippet attribute="excerpt" hit={hit} tagName="mark" />
  </div>
)

export type HitComponent = 'PostHit'
