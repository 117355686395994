import { useCallback, useEffect } from 'react'

type Handler = (event: KeyboardEvent) => void
const defaultHandler: Handler = () => undefined

export const useKeyPress = (
  targetKey: string,
  handler: Handler = defaultHandler,
): void => {
  const keyDownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (document.activeElement) {
        if (
          document.activeElement.localName === 'input' &&
          (document.activeElement as HTMLInputElement).type === 'text'
        ) {
          return
        }
      }

      if (event.key.toLowerCase() === targetKey.toLowerCase()) {
        return handler(event)
      }
    },
    [handler, targetKey],
  )

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)

    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  }, [keyDownHandler])
}
