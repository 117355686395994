import algoliasearch from 'algoliasearch/lite'
import { useClickOutside } from 'hooks/useClickOutside'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { Hits, Index, InstantSearch } from 'react-instantsearch-dom'
import styled from 'styled-components'

import type { HitData } from './HitComponents'
import { PostHit } from './HitComponents'
import { Results } from './Results'
import { SearchBox } from './SearchBox'
import { Stats } from './Stats'
import { HitsWrapper, PoweredBy } from './Styles'
import type { SearchIndex } from './searchIndices'

interface SearchProps {
  searchIndices: SearchIndex[]
  shouldCollapse?: boolean
}

// TODO: fix search triggering THREE request with every change on search input
export const Search: FC<SearchProps> = ({
  searchIndices,
  shouldCollapse = false,
}) => {
  const instantSearchRef = useRef<HTMLDivElement>(null)
  const [query, setQuery] = useState('')
  const [shouldFocus, setShouldFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID || '',
    process.env.GATSBY_ALGOLIA_SEARCH_KEY || '',
  )

  useClickOutside(instantSearchRef, () => setShouldFocus(false))

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearchStateChange = ({ query: instantSearchQuery }: any) => {
    setQuery(instantSearchQuery)
  }

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={searchIndices[0].name}
      onSearchStateChange={handleSearchStateChange}
    >
      <SearchBoxWrapper ref={instantSearchRef}>
        <SearchBox
          onFocus={() => setShouldFocus(true)}
          shouldCollapse={shouldCollapse}
          shouldFocus={shouldFocus}
        />
      </SearchBoxWrapper>
      <HitsWrapper shouldShow={query.length > 0 && shouldFocus}>
        {searchIndices.map(({ name, title }) => (
          <Index key={name} indexName={name}>
            <header>
              <h3>{title}</h3>
              <Stats />
            </header>
            <Results>
              <Hits<HitData>
                hitComponent={({ hit }) => (
                  <PostHit
                    hit={hit}
                    onClick={() => {
                      setShouldFocus(false)
                    }}
                  />
                )}
              />
            </Results>
          </Index>
        ))}
        <PoweredBy />
      </HitsWrapper>
    </InstantSearch>
  )
}

const SearchBoxWrapper = styled.div`
  width: 100%;
  display: block;
`
