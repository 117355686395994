import {
  colorDarkGrayLightWhite,
  colorDarkWhiteLightGray,
  transitionizeProperties,
} from 'constants/theme'

import type { FC } from 'react'
import styled, { css } from 'styled-components'
import { Algolia } from 'styled-icons/fa-brands'
import { Search } from 'styled-icons/fa-solid'

export const SearchIcon = styled(Search)`
  width: 1rem;
  pointer-events: none;
`

const focus = css`
  border: 1px solid ${colorDarkWhiteLightGray};
  background-color: ${colorDarkGrayLightWhite};
  cursor: text;
  width: 100%;

  + ${SearchIcon} {
    color: ${colorDarkWhiteLightGray};
    ${transitionizeProperties(['color'])};
    margin: 0.3rem;
  }
`

const collapse = css<{
  shouldFocus: boolean
}>`
  width: 0;
  cursor: pointer;
  color: ${colorDarkWhiteLightGray};
  margin-left: ${(props) => (props.shouldFocus ? '-1.6rem' : '-1rem')};
  padding-left: ${(props) => (props.shouldFocus ? '1.6rem' : '1rem')};

  + ${SearchIcon} {
    color: ${colorDarkWhiteLightGray};
    ${transitionizeProperties(['color'])};
  }

  ${(props) => props.shouldFocus && focus};

  ::placeholder {
    color: ${({ theme }) => theme.color.grayAlpha};
  }
`

const expand = css`
  background: lightgray;
  width: 6rem;
  margin-left: -1.6rem;
  padding-left: 1.6rem;

  + ${SearchIcon} {
    margin: 0.3rem;
  }
`

export const Input = styled.input<{
  shouldCollapse: boolean
  shouldFocus: boolean
}>`
  outline: none;
  border: none;
  font-size: 1rem;
  background: transparent;
  transition: 0.3s;
  border-radius: 4px;
  ${(props) => (props.shouldCollapse ? collapse : expand)};
`

export const Form = styled.form`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`

const algoliaOverrides = css`
  .ais-Snippet {
    margin-top: 10px;
    display: inline-block;
  }
`

export const HitsWrapper = styled.div<{
  shouldShow: boolean
}>`
  display: ${(props) => (props.shouldShow ? 'grid' : 'none')};
  max-height: 80vh;
  overflow: auto;
  z-index: ${({ theme }) => theme.layers.search};
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 0;
  left: calc(100% + 0.5rem);
  width: 80vw;
  max-width: 30rem;
  box-shadow: 0 0 5px 0;
  padding: 0.7em 1em 0.4rem;
  border-radius: 4px;
  background-color: ${colorDarkGrayLightWhite};
  border: 1px solid ${colorDarkWhiteLightGray};

  @media (max-width: ${({ theme }) => theme.breaks.L}) {
    left: 0;
    top: calc(100% + 0.5rem);
    text-align: left;
  }

  > * + * {
    padding-top: 1em !important;
    border-top: 2px solid darkgray;
  }

  li + li {
    margin-top: 0.7rem;
    padding-top: 0.7rem;
    border-top: 1px solid lightgray;
  }

  * {
    margin-top: 0;
    padding: 0;
  }

  ul {
    list-style: none;
  }

  mark {
    color: ${({ theme }) => theme.color.grayBeta};
    background: ${({ theme }) => theme.color.marker};
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;

    h3 {
      color: white;
      background: ${({ theme }) => theme.color.grayBeta};
      padding: 0.1em 0.4rem;
      border-radius: 4px;
    }
  }

  h3 {
    margin: 0 0 0.5rem;
  }

  h4 {
    margin-bottom: 0.3rem;
  }

  ${algoliaOverrides};
`

const Text = styled.span`
  font-size: 0.6rem;
  text-align: end;
  padding: 0;
`

export const PoweredBy: FC = () => (
  <Text>
    Powered by{' '}
    <a href="https://algolia.com">
      <Algolia size="1em" /> Algolia
    </a>
  </Text>
)
