import { useSocials } from 'hooks/useSocials'
import type { FC } from 'react'
import styled from 'styled-components'

export const Social: FC = () => {
  const socials = useSocials()

  return (
    <SocialBox>
      {socials.map((social) => (
        <SocialLink
          key={social.name}
          href={social.url}
          rel="noopener noreferrer"
          {...(social.name === 'email' && { target: '_self' })}
          title={social.name}
        >
          <SocialIcon backgroundSrc={social.iconUrl} />
        </SocialLink>
      ))}
    </SocialBox>
  )
}

const SocialBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    justify-content: space-around;
    width: 100%;
  }
`

const SocialLink = styled.a`
  border-radius: 50%;
  background-color: white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    width: 60px;
    height: 60px;
  }
`

const SocialIcon = styled.i<{
  backgroundSrc: string
}>`
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-repeat: no-repeat;
  background-size: 25px;
  display: block;
  width: 25px;
  height: 25px;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    background-size: 40px;
    width: 40px;
    height: 40px;
  }
`
