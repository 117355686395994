import { home } from 'constants/routes'
import {
  colorDarkSecondaryLightPrimary,
  transitionizeProperties,
} from 'constants/theme'

import { Link } from 'gatsby'
import { useSiteDetails } from 'hooks/useSiteDetails'
import type { FC } from 'react'
import styled from 'styled-components'

import { NavigationLinks } from './NavigationLinks'
import { Search } from './Search/Search'
import { searchIndices } from './Search/searchIndices'
import { Social } from './Social'

export const Header: FC = () => {
  const { title } = useSiteDetails()

  return (
    <HeaderContainer>
      <Navigation>
        <Title>
          <TitleLink title={title} to={home}>
            {title}
          </TitleLink>
        </Title>
        <Search searchIndices={searchIndices} shouldCollapse />
        <Social />
        <NavigationLinks />
      </Navigation>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  grid-area: sidebar;
  position: sticky;
  align-self: start;
  top: 20px;

  ::after {
    ${transitionizeProperties(['background-color'])};
    background-color: ${colorDarkSecondaryLightPrimary};
    position: absolute;
    content: '';
    width: 5px;
    height: 400px;
    top: 100px;
    right: 0;
  }

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    width: 100%;
    grid-area: header;
    position: relative;

    ::after {
      display: none;
    }
  }
`

const Navigation = styled.nav`
  display: flex;
  padding-right: 20px;
  flex-direction: column;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    flex-direction: column;
    align-items: center;
    padding-right: 0;
  }
`

const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.L};
  display: block;
  margin: 0 0 20px 0;
  align-self: flex-end;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    font-size: ${({ theme }) => theme.font.XXL};
    align-self: unset;
  }
`

const TitleLink = styled(Link)`
  box-shadow: none;
  text-decoration: none;
  background-image: none;
`
