import type { RefObject } from 'react'
import { useCallback, useEffect, useMemo } from 'react'

export const useClickOutside = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: RefObject<any>,
  handler: () => void,
  events?: string[],
): void => {
  let userEvents = useMemo(() => ['mousedown', 'touchstart'], [])

  if (events) {
    userEvents = events
  }

  const handleDetectClickOutside = useCallback(
    (event: Event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        return handler()
      }
    },
    [handler, ref],
  )

  useEffect(() => {
    for (const event of userEvents) {
      document.addEventListener(event, handleDetectClickOutside)
    }

    return () => {
      for (const event of userEvents) {
        document.removeEventListener(event, handleDetectClickOutside)
      }
    }
  }, [handleDetectClickOutside, userEvents])
}
