import { graphql, useStaticQuery } from 'gatsby'

interface GQLSocial {
  allFile: {
    edges: Array<{
      node: {
        name: string
        publicURL: string
      }
    }>
  }
  site: {
    siteMetadata: {
      social: {
        email: string
        github: string
        linkedIn: string
        twitter: string
      }
    }
  }
}

interface Social {
  iconUrl: string
  name: string
  url: string
}

export const useSocials = (): Social[] => {
  const { allFile, site } = useStaticQuery<GQLSocial>(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "svg" }
          absolutePath: { regex: "/icons/social/" }
        }
      ) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
      site {
        siteMetadata {
          social {
            email
            github
            linkedIn
            twitter
          }
        }
      }
    }
  `)

  return Object.entries(site.siteMetadata.social).reduce<Social[]>(
    (acc, social) => {
      const [name, link] = social

      const potentialFile = allFile.edges.find(
        (edge) => edge.node.name.toLowerCase() === name.toLowerCase(),
      )

      if (potentialFile) {
        acc.push({
          iconUrl: potentialFile.node.publicURL,
          name,
          url: link,
        })
      }

      return acc
    },
    [],
  )
}
