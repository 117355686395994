import type { FC } from 'react'
import styled from 'styled-components'

export const ShortcutCheatSheet: FC = () => (
  <ShortcutCheatSheetBox>
    <ShortcutBox>
      <ShortcutName>Open this modal</ShortcutName>
      <ShortcutKeyBox>
        <ShortcutKey title="Key question mark">?</ShortcutKey>
      </ShortcutKeyBox>
    </ShortcutBox>

    <ShortcutBox>
      <ShortcutName>Toggle theme</ShortcutName>
      <ShortcutKeyBox>
        <ShortcutKey title="Key d">D</ShortcutKey>
      </ShortcutKeyBox>
    </ShortcutBox>

    <ShortcutBox>
      <ShortcutName>Go to previous note/post/page</ShortcutName>
      <ShortcutKeyBox>
        <ShortcutKey title="Key arrow left">←</ShortcutKey>
      </ShortcutKeyBox>
    </ShortcutBox>

    <ShortcutBox>
      <ShortcutName>Go to next note/post/page</ShortcutName>
      <ShortcutKeyBox>
        <ShortcutKey title="Key arrow right">→</ShortcutKey>
      </ShortcutKeyBox>
    </ShortcutBox>

    <ShortcutBox>
      <ShortcutName>Close this modal</ShortcutName>
      <ShortcutKeyBox>
        <ShortcutKey title="Key escape">esc</ShortcutKey>
      </ShortcutKeyBox>
    </ShortcutBox>
  </ShortcutCheatSheetBox>
)

const ShortcutCheatSheetBox = styled.section`
  display: flex;
  flex-flow: wrap row;
`

const ShortcutBox = styled.div`
  flex: 33%;
  min-width: 33%;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    flex: 50%;
    min-width: 50%;
  }
`

const ShortcutName = styled.span`
  display: block;
  margin-bottom: 10px;
`

const ShortcutKeyBox = styled.div`
  min-height: 50px;
`

const ShortcutKey = styled.button`
  color: ${({ theme }) => theme.color.grayBeta};
  box-shadow: inset 0 -4px 0 rgba(35, 39, 42, 0.6);
  border: 1px solid #202225;
  background-color: white;
  display: inline-block;
  padding: 3px 6px 4px;
  margin-right: 3px;
  border-radius: 2px;
  cursor: default;
  text-align: center;
  position: relative;

  :active {
    box-shadow: inset 0 -2px 0 #242629;
    border: 1px solid #000;
    transform: translateY(2px);
    padding-bottom: 2px;
  }
`
