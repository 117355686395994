import { Link } from 'gatsby'
import type { FC } from 'react'
import styled from 'styled-components'

import { notes, posts, projects, tags } from '../constants/routes'

import { ThemeSwitch } from './ThemeSwitch'

const routes = {
  notes,
  posts,
  projects,
  tags,
}

export const NavigationLinks: FC = () => {
  return (
    <NavigationLinksContainer>
      <ThemeSwitch />
      {Object.entries(routes).map(([key, path], ii) => (
        <StyledLink
          activeStyle={{
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          key={ii}
          partiallyActive
          title={key}
          to={path}
        >
          {key}
        </StyledLink>
      ))}
    </NavigationLinksContainer>
  )
}

const NavigationLinksContainer = styled.section`
  align-self: end;
  text-align: right;
  width: 100%;
  padding-top: 20px;

  @media (max-width: ${({ theme }) => theme.breaks.M}) {
    padding: 20px 0 20px;
    display: flex;
    justify-content: space-evenly;
    text-align: unset;
    align-self: unset;
  }
`

const StyledLink = styled(Link)`
  display: block;
  text-transform: capitalize;
  box-shadow: none;
  text-decoration: none;
  background-image: none;
  font-size: ${({ theme }) => theme.font.L};
`
